const colors = [
  '#274BDB',
  '#00B383',
  '#DB8B00',
  '#DB2C66',
  '#8F9BB3',
  '#143109',
  '#EE4266',
  '#31081F',
  '#591F0A',
  '#84A98C',
  '#00AFB9',
];
const getBackgroundColorByIndex = (i) => {
  if (i + 1 > colors.length) return theme['color-warning-600'];
  return colors[i];
};

export default getBackgroundColorByIndex;
