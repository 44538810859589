import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

// Sample Usage

function CheckboxButton({
  url,
  style,
  middleTxt,
  selected,
  offerTxt,
  isoffer,
  onPress,
  leftBtn,
  textConStyle,
}) {
  const theme = useTheme();

  return (
    <TouchableOpacity
      style={[
        styles.footerControl,
        style,
        {
          borderColor: selected ? '#ffffff' : '#383838',
        },
      ]}
      onPress={onPress}
    >
      {leftBtn && (
        <View
          style={{
            height: 20,
            width: 20,
          }}
        >
          <Image
            style={{ height: 20, width: 20 }}
            source={
              selected
                ? require('../assets/check.png')
                : require('../assets/dry-clean.png')
            }
          />
        </View>
      )}
      <Text style={{ paddingLeft: 10 }} numberOfLines={2}>
        {middleTxt}
      </Text>
      {isoffer && (
        <View style={styles.offerTxtCon}>
          <Text
            style={[
              styles.offerTxtStyle,
              { color: theme['background-basic-color-4'] },
            ]}
            numberOfLines={1}
          >
            {offerTxt}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
}
CheckboxButton.defaultProps = {
  middleTxt: '',
  selected: false,
  isoffer: false,
  offerTxt: '',
  onPress: () => console.log(': '),
  leftBtn: true,
};
export default CheckboxButton;

const styles = StyleSheet.create({
  footerControl: {
    width: '100%',
    borderRadius: 1,
    borderWidth: 1,
    height: 50,
    alignItems: 'center',
    borderRadius: 25,
    flexDirection: 'row',
    paddingHorizontal: 20,
    borderColor: 'red',
    // justifyContent:"space-between"
  },
  offerTxtCon: {
    paddingLeft: 10,
    width: '33%',
    height: 30,
    borderRadius: 15,
    backgroundColor: '#FFAA00',
    justifyContent: 'center',
    alignItems: 'center',
  },
  offerTxtStyle: {
    fontSize: 12,
    fontWeight: '900',
    color: '#fff',
  },
});
