import { StatusBar } from "expo-status-bar";
import React from "react";
import * as eva from "@eva-design/eva";
import { ApplicationProvider, IconRegistry } from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { AppNavigator } from "./navigation";
import { default as theme } from "./theme.json"; // <-- Import app theme
import { UserContextProvider } from "./hooks/userContext";
import { ThemeContext } from "./hooks/themeContext";
import { registerRootComponent } from "expo";

export default function App() {
  const [theme, setTheme] = React.useState("dark");

  const toggleTheme = () => {
    const nextTheme = theme === "light" ? "dark" : "light";
    setTheme(nextTheme);
  };

  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <UserContextProvider>
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
          <ApplicationProvider {...eva} theme={eva[theme]}>
            <AppNavigator />
          </ApplicationProvider>
        </ThemeContext.Provider>
      </UserContextProvider>
    </>
  );
}

registerRootComponent(App);
