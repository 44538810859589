import strip from 'strip-markdown';
import remark from 'remark';
import React from 'react';
import { Text } from '@ui-kitten/components';
import _ from 'lodash';
export default function ({
  text,
  limit = 25,
  characterLimit = 200,
  keywords = [],
  title,
  type,
}) {
  let cleaned;
  remark()
    .use(strip)
    .process(text, function (err, file) {
      if (err) throw err;
      cleaned = String(file);
    });

  let index;
  let highestMatchCount = 0;
  cleaned = cleaned
    .replace(/\r?\n|\r/g, ' ')
    .trim()
    .toLowerCase();

  // Check for keyword matches
  let splitKeywords = keywords?.join(' ').trim().toLowerCase().split(' ');
  if (keywords?.length) {
    let regexArr = keywords.map((keyword) =>
      keyword.toLowerCase().trim().split(' ').join('|')
    );
    const results = regexArr.map(
      (regex) => (cleaned.match(new RegExp(regex, 'g')) || []).length
    );
    results.forEach((result, i) => {
      if (result > highestMatchCount) {
        index = i;
      }
    });
  }

  function boldKeyword(word) {
    if (!word) return '';
    let text = `${word} `;
    for (let i = 0; i < splitKeywords.length; i++) {
      if (word.toLowerCase() == splitKeywords[i]) {
        text = <Text style={{ fontWeight: '900' }}>{word} </Text>;
      }
    }
    return text;
  }

  let fullText = cleaned.split(' ').map((word, index) => {
    if (!word) return '';
    if (index === 0) return _.capitalize(word) + ' ';
    return boldKeyword(word);
  });
  // truncate if it exceeds word limit
  let shortText = fullText.length > limit ? fullText.slice(0, limit) : fullText;
  return {
    shortText: shortText,
    fullText: fullText,
    ...(keywords && { keyword: keywords[index], keywordIndex: index }),
  };
}
