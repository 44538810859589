import Constants from 'expo-constants';

const isBot = (author) => {
  const keywords = ['bot', 'moderator', 'Bot', 'Tip', 'Mod', 'AutoModerator'];
  return keywords.some((keyword) => author.indexOf(keyword) > -1);
};

export const searchByKeywords = async ({
  alerts = [],
  blockedSubreddits = [],
  blockedUsers = [],
  allowNSFW = false,
  size = 80,
  afterComment = '',
  afterPost = '',
  subscribed = false,
  maxUtc = '',
}) => {
  let keywords = [];
  // let testAlerts = [{ keyword: "recipe filter extension", isExact: false, excludedWords: ["coffee"] }, { keyword: "recipe life story", isExact: false, }, { keyword: "paprika app", isExact: true }, { keyword: 'chrome.google.com/webstore/detail/recipe-filter/ahlcdjbkdaegmljnnncfnhiioiadakae?' }]
  alerts.forEach((alert, i) => {
    if (!alert.keyword) return;
    if (alert.paused) return;
    if (!subscribed && i >= 2) return;
    let formattedAlert = alert.keyword.trim().split(' ').join('+');
    if (alert.isExact) formattedAlert = `"${formattedAlert}"`;
    formattedAlert = `(${formattedAlert})`;
    if (alert.excludedWords?.length)
      formattedAlert =
        formattedAlert +
        `-(${alert.excludedWords
          .map((word) => word.split(' ').join('+'))
          .join('+')})`;
    alert.keyword && keywords.push(formattedAlert);
  });
  if (!keywords.length) {
    return;
  }
  // https://pushshift.io/api-parameters/
  // POSTS https://api.pushshift.io/reddit/submission/search?html_decode=true&q=recipe%20cart%20extension&size=100
  // COMMENTS https://api.pushshift.io/reddit/comment/search?html_decode=true&q=recipe%20cart%20extension&size=100

  // Encoded: 'https://elastic.pushshift.io/rc/comments/_search?source=%7B%22query%22%3A%7B%22bool%22%3A%7B%22must%22%3A%5B%7B%22simple_query_string%22%3A%7B%22query%22%3A%22recipe%20filter%22%2C%22fields%22%3A%5B%22body%22%5D%2C%22default_operator%22%3A%22and%22%7D%7D%5D%2C%22filter%22%3A%5B%7B%22range%22%3A%7B%22created_utc%22%3A%7B%22gte%22%3A1609526213%2C%22lte%22%3A1609612613%7D%7D%7D%5D%2C%22should%22%3A%5B%5D%2C%22must_not%22%3A%5B%5D%7D%7D%2C%22size%22%3A100%2C%22sort%22%3A%7B%22created_utc%22%3A%22desc%22%7D%7D')
  // Decoded: "https://elastic.pushshift.io/rc/comments/_search?source={"query"%3A{"bool"%3A{"must"%3A[{"simple_query_string"%3A{"query"%3A"recipe filter"%2C"fields"%3A["body"]%2C"default_operator"%3A"and"}}]%2C"filter"%3A[{"range"%3A{"created_utc"%3A{"gte"%3A1609526213%2C"lte"%3A1609612613}}}]%2C"should"%3A[]%2C"must_not"%3A[]}}%2C"size"%3A100%2C"sort"%3A{"created_utc"%3A"desc"}}"
  let query = keywords.map((keyword) => encodeURI(keyword)).join('|');
  let formattedSubs = encodeURI(
    blockedSubreddits.map((subreddit) => '!' + subreddit).join(',')
  );
  // let formattedUsers = encodeURI(blockedUsers.map(user => "!" + user).join(','))
  // console.log("comment", `https://api.pushshift.io/reddit/search/comment/?q=${query}${formattedSubs ? '&subreddit=' + formattedSubs : ''}&sort=desc&size=${size}${afterComment ? '&after=' + afterComment : ''}&user_removed=false&mod_removed=false&html_decode=true&reply_delay=%3C40&sub_reply_delay=%3C40&filter=author,body,created_utc,full_link,id,num_comments,permalink,preview,score,selftext,send_replies,subreddit,title,subreddit_subscribers,subreddit_type,upvote_ratio`)
  // console.log("post", `https://api.pushshift.io/reddit/search/submission/?q=${query}${formattedSubs ? '&subreddit=' + formattedSubs : ''}&over_18=${allowNSFW}&sort=desc&size=${size}${afterPost ? '&after=' + afterPost : ''}&html_decode=true&reply_delay=%3C40&filter=author,created_utc,full_link,id,num_comments,preview,score,selftext,send_replies,subreddit,title,subreddit_subscribers,subreddit_type,upvote_ratio`)

  let postSearch = async () => {
    // const queryUrl = `${Constants.manifest.extra.LISTENER_API}/submissions?q=${query}&sort=desc&size=${size}&filter=author,body,created_utc,id,num_comments,permalink,preview,score,send_replies,subreddit,subreddit_subscribers,subreddit_type,upvote_ratio`;
    const queryUrl = `https://api.pushshift.io/reddit/search/submission/?q=${query}${
      formattedSubs ? '&subreddit=' + formattedSubs : ''
    }${
      typeof allowNSFW === 'boolean' ? '&over_18=' + allowNSFW : ''
    }&sort=desc&size=${size}${afterPost ? '&after=' + afterPost : ''}
    &html_decode=true&reply_delay=%3C40&filter=author,created_utc,full_link,id,num_comments,preview,score,selftext,send_replies,subreddit,title,subreddit_subscribers,subreddit_type,upvote_ratio`;
    //  https://beta.pushshift.io/search/reddit/comments?q=chicken&over_18=false&max_created_utc=1626795633
    let response = await fetch(queryUrl, {
      type: 'get',
      Accept: 'application/json, text/plain, */*', // It can be used to overcome cors errors
    });
    return await response.json();
  };

  let commentSearch = async () => {
    // Documentation https://reddit-api.readthedocs.io/en/latest/#comment-search-parameters
    const queryUrl = `https://api.pushshift.io/reddit/search/comment/?q=${query}${
      formattedSubs ? '&subreddit=' + formattedSubs : ''
    }&sort=desc&size=${size}${
      afterComment ? '&after=' + afterComment : ''
    }&user_removed=false&mod_removed=false&html_decode=true&reply_delay=%3C40&sub_reply_delay=%3C40&filter=author,body,created_utc,id,num_comments,permalink,preview,score,send_replies,subreddit,subreddit_subscribers,subreddit_type,upvote_ratio`;
    let response = await fetch(queryUrl, {
      type: 'get',
      Accept: 'application/json, text/plain, */*', // It can be used to overcome cors errors
    });
    return await response.json();
  };

  let betaCommentSearch = async () => {
    const queryUrl = `${
      Constants.manifest.extra.LISTENER_API
    }/comments?q=${query}&over_18=${allowNSFW ? 'true' : 'false'}&sort=desc${
      afterComment ? '&min_created_utc=' + afterComment : ''
    }${
      maxUtc ? '&max_created_utc=' + maxUtc : ''
    }&size=${size}&filter=author,body,created_utc,id,num_comments,permalink,preview,score,send_replies,subreddit,subreddit_subscribers,subreddit_type,upvote_ratio`;
    // console.log(':queryUrl ',queryUrl );
    let response = await fetch(queryUrl, {
      type: 'get',
      Accept: 'application/json, text/plain, */*', // It can be used to overcome cors errors
    });
    return await response.json();
  };

  try {
    let responses = await Promise.all([postSearch(), commentSearch()]);
    let [posts, comments] = responses;
    let cleanPosts = cleanResponse(posts.data);
    let cleanComments = cleanResponse(comments.data);
    return { post: cleanPosts, comment: cleanComments };
  } catch (e) {
    console.log('Api error', e);
  }
  return null;

  // return cleaned;
};

const cleanResponse = (data) => {
  const cResponses = [];
  data.forEach((obj, i) => {
    if (isBot(obj.author)) return;
    cResponses.push({
      ...(obj.author && { author: obj.author }),
      ...(obj.body && { body: obj.body }), //comments have body
      ...(obj.created_utc && { created_utc: obj.created_utc }),
      ...(obj.full_link && { full_link: obj.full_link }),
      ...(obj.id && { id: obj.id }),
      ...(obj.num_comments && { numComments: obj.num_comments }),
      ...(obj.over_18 && { over_18: obj.over_18 }),
      ...(obj.permalink && { permalink: obj.permalink }),
      ...(obj.preview && { preview: obj.preview }),
      ...(obj.score && { score: obj.score }),
      ...(obj.selftext && { selftext: obj.selftext }), //Posts have selftext
      ...(obj.send_replies && { end_replies: obj.send_replies }), // bool
      ...(obj.subreddit && { subreddit: obj.subreddit }),
      ...(obj.title && { title: obj.title }), //Posts have title
      ...(obj.subreddit_subscribers && {
        subreddit_subscribers: obj.subreddit_subscribers,
      }),
      ...(obj.subreddit_type && { subreddit_type: obj.subreddit_type }), //public or private
      ...(obj.upvote_ratio && { upvote_ratio: obj.upvote_ratio }), //public or private
    });
  });
  return cResponses;
};
