import React, { useEffect } from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { Button } from '@ui-kitten/components';
import { GoogleIcon } from '../icons';
import * as SecureStore from 'expo-secure-store';
import { Platform, View } from 'react-native';
import { useUser } from '../hooks/userContext';
import * as AppleAuthentication from 'expo-apple-authentication';
import AppleSignin from 'react-apple-signin-auth';
import Constants from 'expo-constants';

WebBrowser.maybeCompleteAuthSession();
const isApp = Platform.OS !== 'web';

export default ({ toggleLoading }) => {
  const { setUser } = useUser();
  const [request, response, promptAsync] = Google.useAuthRequest({
    expoClientId:
      '1021592440332-f3o77gd8fmj08vb0krch18rpp1p0o3ac.apps.googleusercontent.com',
    webClientId:
      '1021592440332-jnpbu1m7bdla1n1cr364kk4mj66dssl7.apps.googleusercontent.com',
    iosClientId:
      '1021592440332-r6gehno8t3tghoi8b9o68d7hlpqe711l.apps.googleusercontent.com',
    // androidClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
  });

  const completeGoogleLogin = async (token) => {
    try {
      toggleLoading(true);
      // try {
      const response = await fetch(
        `${Constants.manifest.extra.API_URL}/auth/google`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({ token, agent: isApp ? 'ios' : 'web' }),
        }
      );
      let parsed = await response.json();
      if (!isApp) {
        localStorage.setItem('jwt', parsed.jwtToken);
      } else {
        await SecureStore.setItemAsync('jwt', parsed.jwtToken);
      }
      delete parsed.jwtToken;
      if (typeof parsed === 'object' && parsed !== null && !parsed.message) {
        parsed && (await setUser(parsed));
      }
    } catch (err) {
      toggleLoading(false);
      console.error(err);
    }
  };

  const completeAppleLogin = async () => {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
      let { givenName, familyName } = credential.fullName;
      let { user, email } = credential;
      if (!user) console.error('User sub not returned');
      const response = await fetch(
        `${Constants.manifest.extra.API_URL}/auth/apple`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            ...(givenName && { firstName: givenName }),
            ...(familyName && { lastName: familyName }),
            ...(user && { sub: user }),
            ...(email && { email }),
            agent: isApp ? 'ios' : 'web',
          }),
        }
      );
      let parsed = await response.json();
      if (!response.ok) throw new Error(response.status);
      if (!isApp) {
        localStorage.setItem('jwt', parsed.jwtToken);
      } else {
        await SecureStore.setItemAsync('jwt', parsed.jwtToken);
      }
      delete parsed.jwtToken;
      if (typeof parsed === 'object' && parsed !== null && !parsed.message) {
        parsed && (await setUser(parsed));
      }
      // signed in
    } catch (e) {
      if (e.code === 'ERR_CANCELED') {
        console.error('User Cancelled signin', e);
        // handle that the user canceled the sign-in flow
      } else {
        console.error('Other errors', e);
        // handle other errors
      }
    }
  };

  useEffect(() => {
    if (response?.type === 'success') {
      WebBrowser.maybeCompleteAuthSession();
      const { authentication } = response;
      if (authentication) {
        completeGoogleLogin(authentication.accessToken);
      }
    }
  }, [response]);

  return (
    <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
      <Button
        size='large'
        status='primary'
        style={{ width: 250, marginBottom: 16 }}
        accessoryLeft={GoogleIcon}
        onPress={async () => {
          await promptAsync();
        }}
      >
        {' '}
        Sign in with Google
      </Button>

      {!isApp ? (
        <AppleSignin
          /** Auth options passed to AppleID.auth.init() */
          authOptions={{
            clientId: 'app.replyguy.replyguy',
            redirectURI: 'https://web.replyguy.app',
            scope: 'email name',
            state: 'state',
            /** sha256 nonce before sending to apple to unify with native firebase behavior - https://github.com/invertase/react-native-apple-authentication/issues/28 */
            nonce: 'nonce',
            /** We have to usePopup since we need clientSide authentication */
            usePopup: true,
          }}
          onError={(err) => console.error('Error signing in', err)}
          noDefaultStyle={false}
          style={{
            height: 50,
            justifyContent: 'space-evenly',
            fontWeight: 'bold',
          }}
          iconProp={{ style: { marginTop: '10px' } }} // default = undefined
          uiType='light'
          onSuccess={async (res) => {
            try {
              const response = await fetch(
                `${Constants.manifest.extra.API_URL}/auth/apple/web`,
                {
                  method: 'post',
                  headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                  },
                  body: JSON.stringify({
                    ...(res.authorization && {
                      id_token: res.authorization.id_token,
                    }),
                    ...(res.user && { user: res.user }),
                  }),
                }
              );
              let parsed = await response.json();
              if (!isApp) {
                localStorage.setItem('jwt', parsed.jwtToken);
              } else {
                await SecureStore.setItemAsync('jwt', parsed.jwtToken);
              }
              delete parsed.jwtToken;
              if (
                typeof parsed === 'object' &&
                parsed !== null &&
                !parsed.message
              ) {
                parsed && (await setUser(parsed));
              }
            } catch (err) {
              console.error('Error with apple post', err);
            }
          }}
        />
      ) : (
        <Button
          // buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
          // buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
          // cornerRadius={5}
          size='large'
          status='basic'
          style={{ width: 250 }}
          onPress={completeAppleLogin}
        >
          Sign in with Apple
        </Button>
      )}
    </View>
  );
};
