import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  StyleSheet,
  View,
  Alert,
  Share,
  ActivityIndicator,
  Platform,
  Touchable,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Animated,
} from 'react-native';
import {
  Button,
  Card,
  Icon,
  Text,
  List,
  Spinner,
  useTheme,
  Divider,
} from '@ui-kitten/components';
import { searchByKeywords } from '../lib/reddit';
import cleanText from '../utils/cleanText';
import getBackgroundColorByIndex from '../utils/getBackgroundColorByIndex';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as Linking from 'expo-linking';
import { useUser } from '../hooks/userContext';
import { addToBlockedSubreddits, updateReadTimeStamp } from '../lib/user';
import _ from 'lodash';

export const TagIcon = () => (
  <Icon fill='white' style={styles.pillIcon} name='pricetags-outline' />
);

// Sample Usage

function ShareButton({ url }) {
  const onShare = async () => {
    try {
      const result = await Share.share({
        url,
        subject: 'Check out this Reddit comment I saw on ReplyGuy.app',
      });
      // if (result.action === Share.sharedAction) {
      //   if (result.activityType) {
      //     // shared with activity type of result.activityType
      //   } else {
      //     // shared
      //   }
      // } else if (result.action === Share.dismissedAction) {
      //   // dismissed
      // }
    } catch (error) {
      alert(error.message);
    }
  };
  return (
    <Button
      style={styles.footerControl}
      size='small'
      appearance='ghost'
      status='basic'
      accessoryLeft={ShareIcon}
      onPress={onShare}
    >
      Copy Link
    </Button>
  );
}

dayjs.extend(relativeTime);

const onPressReply = (url) => {
  Linking.openURL(url);
};
const MuteIcon = (props) => <Icon {...props} name='volume-off-outline' />;
const LoadingIndicator = (props) => <ActivityIndicator size='small' />;
const ReplyIcon = (props) => <Icon {...props} name='message-square-outline' />;
const ShareIcon = (props) => <Icon {...props} name='link-2-outline' />;

export default ({ filter = 'post', searchTerm, setCount }) => {
  const [results, setResults] = useState({});
  const [loading, toggleLoading] = useState(false);
  const [moreloading, toggleMoreLoading] = useState(false);
  const {
    user,
    setUser,
    isUserLoaded,
    subscription,
    lastReadTimestamp,
    setLastReadTimestamp,
  } = useUser();
  const listRef = useRef();
  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollToOffset({ animated: true, offset: 0 });
    }
  };
  const theme = useTheme();

  const onRefresh = useCallback(() => {
    toggleLoading(true);
    fetchResults(user, searchTerm).then(() => toggleLoading(false));
  }, [user]);

  const fetchResults = async (user, searchTerm, maxUtc = '') => {
    try {
      if (user?.alerts?.length || searchTerm) {
        let { alerts, blockedSubreddits, blockedUsers, allowNSFW } = user;
        // if (!subscription) alerts = alerts && [...alerts].reverse().slice(0, 1)
        // if (!!searchTerm) {
        //   console.log('serch term exists');
        //   alerts = [{ keyword: searchTerm }];
        // }
        let response = await searchByKeywords({
          alerts,
          blockedSubreddits,
          blockedUsers,
          allowNSFW,
          subscribed: !!subscription,
          maxUtc,
        });

        toggleLoading(false);
        if (response) {
          toggleMoreLoading(false);
          // ignore saved alerts if user is searching
          // if (!!searchTerm) {
          //   console.log('serch term exists');
          //   return setResults({
          //     comment: response.comment,
          //   });
          // }
          console.log('Appending', results?.comment && !!maxUtc);
          const appendComArray =
            results?.comment && !!maxUtc
              ? [...results.comment, ...response.comment]
              : response.comment;
          const appendPostArray =
            results?.post && !!maxUtc
              ? [...results.post, ...response.post]
              : response.post;
          // var commentCount = _.filter(appendComArray, ({ created_utc }) => created_utc <
          //   user?.lastReadCommentTimestamp).length;
          // var postCount = _.filter(appendPostArray, ({ created_utc }) => created_utc <
          //   user?.lastReadPostTimestamp).length;
          // setCount({
          //   commentCount,
          //   postCount
          // })
          setResults({
            comment: appendComArray,
            post: appendPostArray,
          });
        }
      }
    } catch (err) {
      toggleLoading(false);
      toggleMoreLoading(false);
      console.error('User error', err);
    }
  };

  useEffect(() => {
    // If comments/posts toggled, then scroll to top
    scrollToTop();
  }, [filter]);

  useEffect(() => {
    if (results?.[filter]?.[0]?.created_utc) {
      updateReadTimeStamp(filter, results?.[filter]?.[4]?.created_utc);
      setLastReadTimestamp(results?.[filter]?.[4]?.created_utc);
    }
  }, [results]);

  useEffect(() => {
    if (isUserLoaded && user?.alerts.length) {
      toggleLoading(true);
      fetchResults(user, searchTerm);
    } else {
      setResults({});
    }
  }, [isUserLoaded, user, searchTerm]);

  const renderPost = ({ item }) => {
    return <StatefulCard key={item.id} item={item} type='post' />;
  };
  const renderComment = ({ item, index }) => {
    return <StatefulCard key={item.id} item={item} type='comment' />;
  };

  const StatefulCard = ({ props, item, type, key }) => {
    let [expanded, setExpanded] = useState(false);
    const toggleExpand = () => {
      setExpanded(!expanded);
    };
    const theme = useTheme();
    let keywords = user?.alerts?.map((alert) => alert.keyword);
    let text = type === 'comment' ? item.body : item.selftext;
    let { shortText, fullText, keyword, keywordIndex } = cleanText({
      text,
      keywords,
      title: item.title,
      type,
    });
    let url =
      type === 'comment'
        ? `https://www.reddit.com${item.permalink}`
        : item.full_link;

    return (
      !!shortText && (
        <TouchableOpacity
          key={key}
          style={[
            {
              flex: 1,
              paddingVertical: 8,
              flexDirection: 'row',
              alignItems: 'flex-start',
              backgroundColor: theme['background-basic-color-2'],
            },
          ]}
          onPress={() => onPressReply(url)}
        >
          {/* White siding */}
          {/* {console.log(
            'Last read',
            user.lastReadPostTimestamp,
            item.created_utc
          )} */}
          <View
            style={[
              {
                color: 'white',
                width: 4,
                height: 120,
                borderRadius: 16,
                marginHorizontal: 8,
              },
              filter == 'post'
                ? {
                    backgroundColor:
                      lastReadTimestamp > item.created_utc
                        ? '#383838'
                        : '#ffffff',
                  }
                : {
                    backgroundColor:
                      lastReadTimestamp > item.created_utc
                        ? '#383838'
                        : '#ffffff',
                  },
            ]}
          />
          <View
            style={[
              {
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
              },
            ]}
          >
            <View style={{ flex: 1 }}>
              <Text style={{ lineHeight: 22 }}>
                {!!item.title && (
                  <Text style={styles.titleText}>{item.title + '\n'}</Text>
                )}
                {text && expanded ? (
                  <Text>{fullText}</Text>
                ) : (
                  <Text>{shortText}</Text>
                )}
                {shortText.length !== fullText.length && (
                  <TouchableOpacity
                    onPress={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggleExpand();
                    }}
                    onTouchEnd={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Text style={{ fontWeight: '800' }}>
                      {expanded ? ' View Less' : '... View More'}
                    </Text>
                  </TouchableOpacity>
                )}
              </Text>

              {!!keyword && (
                <View
                  numberOfLines={1}
                  category='s2'
                  style={[
                    styles.pill,
                    {
                      backgroundColor: getBackgroundColorByIndex(keywordIndex),
                    },
                  ]}
                >
                  <TagIcon />
                  <Text style={styles.pillText}>{keyword}</Text>
                </View>
              )}
              {!!item.score && !!item.subreddit && (
                <Text
                  category='p2'
                  style={{ color: theme['color-basic-600'], marginBottom: 4 }}
                >
                  {`↑ ${item.score} · Posted ${dayjs(
                    item.created_utc * 1000
                  ).fromNow()} to r/${item.subreddit}`}
                </Text>
              )}
            </View>
            <Footer
              setUser={setUser}
              // props={props}
              url={url}
              subreddit={item.subreddit}
            />
            <Divider />
          </View>
        </TouchableOpacity>
      )
    );
  };
  const Footer = ({ url, subreddit, setUser }) => {
    const [isMuted, toggleMuted] = useState(false);

    return (
      <View style={[styles.footerContainer]}>
        {/* <Button
          style={styles.footerControl}
          size='small'
          appearance='ghost'
          status='primary'
          accessoryLeft={ReplyIcon}
          onPress={() => onPressReply(url)}
        >
          Reply
        </Button> */}
        <Button
          size='small'
          status='danger'
          appearance='ghost'
          accessoryLeft={isMuted ? LoadingIndicator : MuteIcon}
          onPress={async () => {
            try {
              toggleMuted(true);
              let foundUser = await addToBlockedSubreddits(subreddit);
              if (foundUser) setUser(foundUser);
              Alert.alert(
                'Subreddit muted',
                `You will no longer see activity from r/${subreddit}`,
                [],
                { cancelable: false }
              );
            } catch (err) {
              console.log(err);
            }
          }}
        >
          {'Mute'}
        </Button>
        <ShareButton url={url} />
      </View>
    );
  };

  function onLoadMore(params) {
    const maxUtc =
      results[filter] &&
      results[filter][results[filter].length - 1].created_utc;
    !moreloading && fetchResults(user, searchTerm, maxUtc);
    toggleMoreLoading(true);
    //.then(() => toggleMoreLoading(false));
  }

  const Loader = (params) => (
    <View style={{ marginTop: 10, alignSelf: 'center' }}>
      <Spinner status='primary' size='large' style={styles.spinner} />
    </View>
  );

  const keyExtractor = (item, index) => index.toString();
  return (
    <>
      {!loading && isUserLoaded && results[filter]?.length ? (
        <List
          style={styles.container}
          data={results[filter]}
          refreshing={loading}
          onRefresh={onRefresh}
          maxToRenderPerBatch={7}
          windowSize={12}
          renderItem={filter === 'post' ? renderPost : renderComment}
          keyExtractor={keyExtractor}
          ref={listRef}
          onEndReached={onLoadMore}
          onEndReachedThreshold={0.5}
          ListFooterComponent={() => (
            <View
              style={{
                height: 40,
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: 10,
              }}
            >
              {moreloading && <Loader />}
            </View>
          )}
        ></List>
      ) : loading ? (
        <Loader />
      ) : (
        <Text
          style={{ marginTop: 16, textAlign: 'center' }}
          status='warning'
          category='s1'
        >
          Create keyword alerts to populate this feed
        </Text>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: 0,
    paddingVertical: 0,
  },
  footerControl: {
    marginRight: 0,
    padding: 0,
  },
  pill: {
    borderRadius: 20,
    alignSelf: 'flex-start',
    textAlign: 'center',
    alignItems: 'center',
    paddingHorizontal: 6,
    paddingVertical: 8,
    marginVertical: 8,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  pillText: {
    color: 'white',
    fontWeight: '800',
  },
  pillIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
  titleText: { fontWeight: '800', marginBottom: 8 },
});
