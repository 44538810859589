import React, { useRef, useState } from 'react';
import {
  SafeAreaView,
  ScrollView,
  Text,
  StyleSheet,
  View,
  ImageBackground,
  Animated,
  useWindowDimensions,
  Dimensions,
  Image,
} from 'react-native';
import { Button, useTheme } from '@ui-kitten/components';
import { completeOnboarding } from '../lib/user';
import AsyncStorage from '@react-native-async-storage/async-storage';

const images = new Array(6).fill(
  'https://images.unsplash.com/photo-1556740749-887f6717d7e4'
);
let height = Dimensions.get('window').height * 0.5;
const OnboardingSwiper = ({ navigation }) => {
  const theme = useTheme();
  const scrollX = useRef(new Animated.Value(0)).current;
  const sliderRef = useRef();
  const { width: windowWidth } = useWindowDimensions();
  const backgroundColor = (isLight) => (isLight ? 'blue' : 'lightblue');
  const color = (isLight) => backgroundColor(!isLight);
  const [currentIndex, updateCurrentIndex] = useState(1);

  const array = [
    {
      backgroundColor: theme['color-basic-700'],
      image: require('../media/subreddits.png'),
      title: 'Welcome to Reply Guy for Reddit',
      subtitle: '54M people visit Reddit daily - start interacting with them',
      titleStyles: { color: '#fff' }, // overwrite default color
      subTitleStyles: { color: '#fff' },
    },
    {
      backgroundColor: theme['border-success-color-5'],
      image: require('../media/shoes.png'),
      title: 'Reddit influences purchase decisions',
      subtitle:
        'People look to Reddit for recommendations. Reddit has the highest Net Promoter Score of any social media.',
      titleStyles: { color: '#fff' }, // overwrite default color
      subTitleStyles: { color: '#fff' },
    },
    {
      backgroundColor: '#ff4500',
      image: require('../media/notifications.png'),
      title:
        'Reply Guy is not just another Reddit app- it monitors all (140,000+) subreddits in real time',
      subtitle: 'Instantly view new and past mentions from all of Reddit.',
      titleStyles: { color: '#fff' }, // overwrite default color
      subTitleStyles: { color: '#fff' },
    },
    {
      backgroundColor: theme['background-basic-color-2'],
      image: require('../media/create-alert.gif'),
      title: 'First, create alerts for keywords and URLs to populate your feed',
      subtitle:
        'Get email or app alerts and reply early to be seen by thousands.',
      titleStyles: { color: theme['text-basic-color'] },
    },
    {
      backgroundColor: theme['background-basic-color-3'],
      image: require('../media/reply-sub.gif'),
      title: 'Then, reply to comments and posts in your niche',
      subtitle:
        'Find customers, drive traffic to your site, and interact with friendly Redditors.',
      titleStyles: { color: theme['text-basic-color'] },
    },
    {
      backgroundColor: theme['background-basic-color-3'],
      image: require('../media/mute-subreddit.gif'),
      title: 'As you learn, continue to refine your feed',
      subtitle:
        'Add more keywords and mute off-topic subreddits to improve your results.',
      titleStyles: { color: theme['text-basic-color'] },
    },
  ];
  const Skip = ({ isLight, skipLabel, ...props }) => (
    <Button
      title={'Skip'}
      buttonStyle={{
        backgroundColor: backgroundColor(isLight),
      }}
      containerViewStyle={{
        marginVertical: 10,
        width: 70,
      }}
      textStyle={{ color: color(isLight) }}
      {...props}
    >
      {skipLabel}
    </Button>
  );
  const onSkip = async () => {
    try {
      completeOnboarding();
      await AsyncStorage.setItem(
        'completedOnboarding',
        JSON.stringify({ completedOnboarding: true })
      );
      navigation.navigate('Home');
    } catch (e) {
      console.log('Error- ', e);
    }
  };

  const Next = ({ isLight, ...props }) => (
    <Button
      title={array.length === currentIndex ? 'Done' : 'Next'}
      buttonStyle={{
        backgroundColor: backgroundColor(isLight),
        backgroundColor: 'red',
      }}
      containerViewStyle={{
        // marginHorizontal: 10,
        width: 70,
        backgroundColor: backgroundColor(isLight),
        backgroundColor: 'red',
      }}
      textStyle={{ color: color(isLight) }}
      {...props}
    />
  );
  return (
    <SafeAreaView
      style={[styles.container, { backgroundColor: theme['color-basic-700'] }]}
    >
      <ScrollView
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          position: 'absolute',
          right: 0,
          left: 0,
          top: 0,
          bottom: 0,
        }}
        horizontal={true}
        style={{ position: 'absolute', right: 0, left: 0, top: 0, bottom: 0 }}
        scrollEnabled={true}
        onScroll={Animated.event([
          {
            nativeEvent: {
              contentOffset: {
                x: scrollX,
              },
            },
          },
        ])}
        ref={sliderRef}
        scrollEventThrottle={1}
      >
        {array.map((item, imageIndex) => {
          return (
            <View
              style={{
                height: '100%',
                width: windowWidth,
                backgroundColor: item.backgroundColor,
                alignItems: 'center',
                paddingTop: 40,
              }}
              key={imageIndex}
            >
              <Image
                style={{
                  height: '65%',
                  width: windowWidth / 2,
                  resizeMode: 'contain',
                  borderRadius: 8,
                }}
                source={item.image}
              />
              <Text
                style={[
                  item.titleStyles,
                  {
                    fontSize: 16,
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    padding: 16,
                  },
                ]}
              >
                {item.title}
              </Text>
              <Text
                style={[
                  {
                    fontSize: 16,
                    justifyContent: 'center',
                    color: '#fff',
                    padding: 16,
                  },
                ]}
              >
                {item.subtitle}
              </Text>
            </View>
          );
        })}
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 10,
          right: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: 20,
        }}
      >
        {array.length !== currentIndex ? (
          <Button onPress={() => onSkip()}>Skip</Button>
        ) : (
          <View />
        )}
        {array.length !== currentIndex ? (
          <View style={styles.indicatorContainer}>
            {images.map((image, imageIndex) => {
              const width = scrollX.interpolate({
                inputRange: [
                  windowWidth * (imageIndex - 1),
                  windowWidth * imageIndex,
                  windowWidth * (imageIndex + 1),
                ],
                outputRange: [8, 16, 8],
                extrapolate: 'clamp',
              });
              return (
                <Animated.View
                  key={imageIndex}
                  style={[styles.normalDot, { width }]}
                />
              );
            })}
          </View>
        ) : (
          <View />
        )}
        <Button
          style={
            array.length === currentIndex
              ? {}
              : { backgroundColor: 'transparent', borderWidth: 0 }
          }
          onPress={() => {
            array.length === currentIndex
              ? onSkip()
              : sliderRef.current.scrollTo({
                  x:
                    array.length === currentIndex
                      ? 0
                      : windowWidth * currentIndex,
                  y: 0,
                  animated: true,
                });
            array.length === currentIndex
              ? updateCurrentIndex(1)
              : updateCurrentIndex(currentIndex + 1);
          }}
        >
          {array.length === currentIndex ? 'Done' : 'Next'}
        </Button>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollContainer: {
    height: 300,
  },
  card: {
    flex: 1,
    // marginVertical: 4,
    // marginHorizontal: 16,
    borderRadius: 5,
  },
  textContainer: {
    backgroundColor: 'rgba(0,0,0, 0.7)',
    paddingHorizontal: 24,
    paddingVertical: 8,
    borderRadius: 5,
  },
  infoText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  normalDot: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: 'silver',
    marginHorizontal: 4,
  },
  indicatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default OnboardingSwiper;
