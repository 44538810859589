import React from "react";
import StripeCheckout from "../components/StripeCheckout";
import { View } from "react-native";
import Constants from "expo-constants";

const MyStripeCheckout = ({ CHECKOUT_SESSION_ID }) => {
  return (
    <View style={{ flex: 1, alignSelf: "stretch" }}>
      <StripeCheckout
        stripePublicKey={Constants.manifest.extra.STRIPE_PUBLIC_KEY}
        checkoutSessionInput={{
          sessionId: CHECKOUT_SESSION_ID,
        }}
        onSuccess={({ checkoutSessionId }) => {
          console.log(
            `Stripe checkout session succeeded. session id: ${checkoutSessionId}.`
          );
        }}
        onLoadingComplete={() => console.log("Complete")}
        onCancel={() => {
          console.log(`Stripe checkout session cancelled.`);
        }}
        options={{
          htmlContentLoading:
            "<h1 style='width:100%; text-align: center'>Loading...</h1>",
        }}
      />
    </View>
  );
};

export default MyStripeCheckout;
