import React, { useEffect, useState, useRef } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Text, Button, useTheme, Icon } from '@ui-kitten/components';
import { useUser } from '../hooks/userContext';
import {
  View,
  Alert,
  StyleSheet,
  Animated,
  ScrollView,
  ImageBackground,
  Dimensions,
  Platform,
} from 'react-native';
import Purchases from 'react-native-purchases';
import StripeCheckout from '../screens/StripeCheckout';
import Constants from 'expo-constants';
import { CheckboxButton } from '../components';
const isApp = Platform.OS !== 'web';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const styles = StyleSheet.create({
  row: {
    padding: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 300,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
});

const getOfferings = async (setPackages) => {
  try {
    Purchases.setup(Constants.manifest.extra.RC_PUBLIC_KEY);
    const offerings = await Purchases.getOfferings();
    if (
      offerings.current &&
      offerings?.current.availablePackages.length !== 0
    ) {
      setPackages(offerings.current.availablePackages);
    }
  } catch (e) {
    console.error('Cant fetch offerings', e);
  }
};

export default function BuyModal({ navigation }) {
  const [packages, setPackages] = useState([]);
  const [selectPackages, setSelectPackages] = useState('');
  const [stripeSessionId, setStripeSessionId] = useState('');
  const { setSubscriptionStatus, user } = useUser();
  const theme = useTheme();
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const trialAnim = useRef(new Animated.Value(0)).current;
  const buyAnim = useRef(new Animated.Value(0)).current;
  const handleStripeCheckout = async (priceId) => {
    try {
      let res = await fetch(
        `${Constants.manifest.extra.API_URL}/checkout/stripe`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            priceId: priceId,
            email: user.email,
            id: user.id,
          }),
        }
      );
      return await res.json();
    } catch (err) {
      console.error('stripe fetch error', err);
    }
  };

  useEffect(() => {
    try {
      fadeIn();
      if (isApp) {
        Purchases.setup(Constants.manifest.extra.RC_PUBLIC_KEY);
        Purchases.setDebugLogsEnabled(true);
        getOfferings(setPackages);
      }
    } catch (err) {
      console.error(err);
    }
  });
  useEffect(() => {
    try {
      if (Platform.OS === 'web') {
        setSelectPackages(2);
      } else if (packages) {
        packages?.length > 0 && setSelectPackages(packages?.length - 1);
      }
    } catch (err) {
      console.error(err);
    }
  }, [packages]);

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start(() => {
      Animated.timing(trialAnim, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }).start(() => {
        Animated.timing(buyAnim, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }).start();
      });
    });
  };
  if (stripeSessionId) {
    return <StripeCheckout CHECKOUT_SESSION_ID={stripeSessionId} />;
  }

  return (
    <View
      style={{
        flex: 1,
        height: 150,
      }}
    >
      {!isApp ? (
        <>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme['background-basic-color-4'],
            }}
          >
            <Text category='h2' style={{ marginBottom: 16 }}>
              Pro Subscription
            </Text>
            <View style={{ justifyContent: 'center' }}>
              <Animated.View style={[styles.row, { opacity: fadeAnim }]}>
                <Icon
                  style={styles.icon}
                  name='checkmark-outline'
                  fill={'gray'}
                />
                <Text category='s1'>Unlimited keyword alerts</Text>
              </Animated.View>
              <Animated.View style={[styles.row, { opacity: fadeAnim }]}>
                <Icon
                  style={styles.icon}
                  name='checkmark-outline'
                  fill={'gray'}
                />
                <Text category='s1'>Unlimited email alerts</Text>
              </Animated.View>
              <Animated.View style={[styles.row, { opacity: fadeAnim }]}>
                <Icon
                  style={styles.icon}
                  name='checkmark-outline'
                  fill={'gray'}
                />
                <Text category='s1'>Includes web and mobile app access</Text>
              </Animated.View>
              <Animated.View style={[styles.row, { opacity: fadeAnim }]}>
                <Icon
                  style={styles.icon}
                  name='checkmark-outline'
                  fill={'gray'}
                />
                <Text category='s1'>Easily cancel anytime</Text>
              </Animated.View>
              <Animated.View style={[styles.row, { opacity: fadeAnim }]}>
                <Icon style={styles.icon} name='heart-outline' fill={'gray'} />
                <Text category='s1'>20% donated to Open Source</Text>
              </Animated.View>
            </View>

            {/* <Divider style={{ alignSelf: 'stretch', backgroundColor: theme['color-basic-600'], paddingHorizontal: 16 }} /> */}
            <Animated.View style={{ opacity: trialAnim }}>
              <Text
                category='p1'
                style={{
                  alignSelf: 'stretch',
                  paddingHorizontal: 24,
                  marginBottom: 16,
                  marginTop: 8,
                  textAlign: 'center',
                  fontStyle: 'italic',
                }}
              >
                Find new customers, grow your organic traffic, and monitor your
                brand.
              </Text>
            </Animated.View>

            <Animated.View styles={{ opacity: buyAnim }}>
              {Platform.OS === 'web' ? (
                <>
                  <CheckboxButton
                    selected={selectPackages === 1}
                    onPress={async () => {
                      setSelectPackages(1);
                    }}
                    style={{ width: 315, marginTop: 16 }}
                    middleTxt={'$13.99 / Month'}
                    leftBtn={true}
                  />
                  <CheckboxButton
                    selected={selectPackages === 2}
                    onPress={async () => {
                      setSelectPackages(2);
                    }}
                    style={{ width: 315, marginTop: 16 }}
                    textConStyle={{ flexDirection: 'row' }}
                    middleTxt={'1 Week free trial, then $34.99 / Year'}
                    leftBtn={true}
                    isoffer={true}
                    offerTxt={'Save 70%'}
                  />
                  <CheckboxButton
                    selected={false}
                    onPress={async () => {
                      if (selectPackages === 1 || selectPackages === 2) {
                        try {
                          let res = await handleStripeCheckout(
                            selectPackages == 1
                              ? Constants.manifest.extra
                                  .STRIPE_PRODUCT_ID_MONTHLY
                              : Constants.manifest.extra
                                  .STRIPE_PRODUCT_ID_YEARLY
                          );
                          if (res.sessionId) {
                            setStripeSessionId(res.sessionId);
                          }
                          // navigation.navigate("StripeCheckout");
                        } catch (err) {
                          console.error('error stripe', err);
                        }
                      }
                    }}
                    style={{
                      width: 315,
                      marginTop: 16,
                      backgroundColor: '#274BDB',
                      justifyContent: 'center',
                      paddingLeft: 0,
                      borderWidth: 0,
                    }}
                    textConStyle={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingLeft: 0,
                    }}
                    middleTxt={'Continue'}
                    leftBtn={false}
                  />
                </>
              ) : (
                packages.map((rcPackage, index) => {
                  return (
                    <CheckboxButton
                      selected={selectPackages === index}
                      onPress={async () => {
                        setSelectPackages(index);
                      }}
                      style={{ width: 315, marginTop: 16 }}
                      middleTxt={`7 days free, then ${rcPackage.product.price_string} / ${rcPackage.packageType}`}
                    />
                  );
                })
              )}
              {isApp && (
                <CheckboxButton
                  selected={false}
                  onPress={async () => {
                    if (selectPackages === '' && selectPackages !== 0)
                      alert('Please select pacakage');
                    else {
                      try {
                        const {
                          purchaserInfo,
                          productIdentifier,
                          userCancelled,
                        } = await Purchases.purchasePackage(
                          packages[selectPackages]
                        );
                        if (userCancelled) return;
                        if (
                          typeof purchaserInfo.entitlements.active
                            .premiumAlerts !== 'undefined'
                        ) {
                          await AsyncStorage.setItem(
                            'subscribed',
                            purchaserInfo.entitlements.active.premiumAlerts
                              .productIdentifier
                          );
                          setSubscriptionStatus(productIdentifier);
                          Alert.alert(
                            'Thank you for subscribing.',
                            '20% of your subscription will be donated to Open Source Software. Enjoy your unlimited alerts.'
                          );
                          navigation.navigate('Home');
                        }
                        // else if (typeof purchaserInfo.entitlements.active.premiumAlerts !== "undefined") {

                        // }
                      } catch (err) {
                        console.error('Error', err);
                      }
                    }
                  }}
                  style={{
                    width: 315,
                    marginTop: 16,
                    backgroundColor: '#274BDB',
                    justifyContent: 'center',
                    paddingLeft: 0,
                    borderWidth: 0,
                  }}
                  textConStyle={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: 0,
                  }}
                  middleTxt={'Continue'}
                  leftBtn={false}
                />
              )}

              <Button
                style={{ marginTop: 16 }}
                onPress={() => navigation.navigate('Home')}
                appearance='ghost'
                status='danger'
                title='Dismiss'
              >
                Dismiss
              </Button>
            </Animated.View>
          </ScrollView>
        </>
      ) : (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
          <ScrollView showsVerticalScrollIndicator={true}>
            <ImageBackground
              source={require('../assets/BuyImage.jpg')}
              style={{
                height: 3000,
                width: windowWidth,
                alignSelf: 'flex-start',
                flex: 1,
                marginTop: 36,
              }}
              imageStyle={{ resizeMode: 'contain' }}
              // imageStyle={{ width: windowWidth }}
            ></ImageBackground>
          </ScrollView>

          <Animated.View
            styles={{ opacity: buyAnim }}
            style={{
              // backgroundColor: theme['background-basic-color-4'],
              backgroundColor: 'white',
              paddingHorizontal: 20,
              borderRadius: 8,
              alignItems: 'center',
              position: 'absolute',
              width: '100%',
              bottom: 0,
            }}
          >
            {packages.map((rcPackage, index) => {
              return (
                <CheckboxButton
                  selected={selectPackages === index}
                  onPress={async () => {
                    setSelectPackages(index);
                  }}
                  style={{ width: 315, marginTop: 16 }}
                  middleTxt={`7 days free, then ${rcPackage.product.price_string} / ${rcPackage.packageType}`}
                />
              );
            })}
            {
              <CheckboxButton
                selected={false}
                onPress={async () => {
                  if (selectPackages === '' && selectPackages !== 0)
                    alert('Please select pacakage');
                  else {
                    try {
                      const {
                        purchaserInfo,
                        productIdentifier,
                        userCancelled,
                      } = await Purchases.purchasePackage(
                        packages[selectPackages]
                      );
                      if (userCancelled) return;
                      if (
                        typeof purchaserInfo.entitlements.active
                          .premiumAlerts !== 'undefined'
                      ) {
                        await AsyncStorage.setItem(
                          'subscribed',
                          purchaserInfo.entitlements.active.premiumAlerts
                            .productIdentifier
                        );
                        setSubscriptionStatus(productIdentifier);
                        Alert.alert(
                          'Thank you for subscribing.',
                          '20% of your subscription will be donated to Open Source Software. Enjoy your unlimited alerts.'
                        );
                        navigation.navigate('Home');
                      }
                      // else if (typeof purchaserInfo.entitlements.active.premiumAlerts !== "undefined") {

                      // }
                    } catch (err) {
                      console.error('Error', err);
                    }
                  }
                }}
                style={{
                  width: 315,
                  marginTop: 16,
                  backgroundColor: '#274BDB',
                  justifyContent: 'center',
                  paddingLeft: 0,
                  borderWidth: 0,
                }}
                textConStyle={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 0,
                }}
                middleTxt={'Continue'}
                leftBtn={false}
              />
            }
            <Button
              style={{ marginTop: 16 }}
              onPress={() => navigation.navigate('Home')}
              appearance='ghost'
              status='danger'
              title='Dismiss'
            >
              Dismiss
            </Button>
          </Animated.View>
        </View>
      )}
    </View>
  );
}
