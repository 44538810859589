import React, { useState } from "react";
import { View, SafeAreaView, Dimensions, Image } from "react-native";
import {
  Button,
  CheckBox,
  Spinner,
  Divider,
  Input,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from "@ui-kitten/components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Auth } from "../components";

export default ({ navigation }) => {
  // const [firstName, setFirstName] = useState();
  // const [lastName, setLastName] = useState();
  // const [email, setEmail] = useState();
  // const [password, setPassword] = useState();
  // const [dob, setDob] = useState();
  // const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: theme["background-basic-color-4"] }}
    >
      <View style={styles.container}>
        {/* <Divider style={styles.divider} /> */}
        <Image
          style={{ height: 160, width: 160, padding: 60, alignSelf: "center" }}
          source={require("../assets/icon.png")}
        />
        <Text
          style={{
            alignSelf: "center",
            marginVertical: 8,
          }}
          category="h2"
        >
          Reply Guy
        </Text>
        <Text
          style={{ textAlign: "center", padding: 8 }}
          category="h6"
          status="basic"
        >
          Keyword alerts for Reddit
        </Text>
        <View style={styles.socialAuthContainer}>
          <Text category="s1" style={styles.socialAuthHintText}>
            Sign with a social account
          </Text>
          <View style={styles.socialAuthButtonsContainer}>
            {/* <Button
            appearance='ghost'
            size='giant'
            status='basic'
            accessoryLeft={GoogleIcon}
          /> */}
            {loading ? (
              <View style={styles.spinnerStyle}>
                <Spinner status="primary" size="large" />
              </View>
            ) : (
              <Auth toggleLoading={toggleLoading} />
            )}
            {/* <Button
              appearance='ghost'
              size='giant'
              status='basic'
              icon={FacebookIcon}
            /> */}
            {/*<Button
            appearance='ghost'
            size='giant'
            status='basic'
            icon={TwitterIcon}
          /> */}
          </View>
        </View>

        {/* 


        <View style={styles.orContainer}>
          <Divider style={styles.divider} />
          <Text
            style={styles.orLabel}
            category='h5'>
            OR
        </Text>
          <Divider style={styles.divider} />
        </View>
        <Text
          style={styles.emailSignLabel}>
          Sign up with Email
        </Text>
        <View style={[styles.container, styles.formContainer]}>
          <Input
            placeholder='Ally'
            label='FIRST NAME'
            autoCapitalize='words'
            value={firstName}
            onChangeText={setFirstName}
          />
          <Input
            style={styles.formInput}
            placeholder='Watsan'
            label='LAST NAME'
            autoCapitalize='words'
            value={lastName}
            onChangeText={setLastName}
          />
          {/* <Datepicker
          style={styles.formInput}
          placeholder='18/10/1995'
          label='Date of Birth'
          date={dob}
          onSelect={setDob}
        /> 
        <Input
          style={styles.formInput}
          placeholder='ally.watsan@gmail.com'
          label='EMAIL'
          value={email}
          onChangeText={setEmail}
        />
        <Input
          style={styles.formInput}
          label='PASSWORD'
          placeholder='Password'
          secureTextEntry={true}
          value={password}
          onChangeText={setPassword}
        />
        <CheckBox
          style={styles.termsCheckBox}
          textStyle={styles.termsCheckBoxText}
          checked={termsAccepted}
          onChange={(checked) => setTermsAccepted(checked)}
        >By creating an account, I agree to the Ewa Terms of Use and Privacy Policy</CheckBox>
      </View>
      <Button
        style={styles.signUpButton}
        size='large'
        onPress={onSignUpButtonPress}>
        SIGN UP
      </Button> */}
      </View>
    </SafeAreaView>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: "background-basic-color-4",
    flex: 1,
    justifyContent: "center",
  },
  headerContainer: {
    minHeight: 216,
    paddingHorizontal: 16,
    paddingTop: 24,
    paddingBottom: 44,
  },
  signUpContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32,
  },
  socialAuthContainer: {
    marginTop: 36,
    justifyContent: "center",
  },
  socialAuthButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  socialAuthHintText: {
    alignSelf: "center",
    marginBottom: 16,
  },
  formContainer: {
    marginTop: 48,
    paddingHorizontal: 16,
  },
  evaButton: {
    maxWidth: 72,
    paddingHorizontal: 0,
  },
  signInLabel: {
    flex: 1,
  },
  signInButton: {
    flexDirection: "row-reverse",
    paddingHorizontal: 0,
  },
  signUpButton: {
    marginVertical: 24,
    marginHorizontal: 16,
  },
  socialAuthIcon: {
    tintColor: "text-basic-color",
  },
  orContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    marginTop: 52,
  },
  divider: {
    flex: 1,
  },
  orLabel: {
    marginHorizontal: 8,
  },
  emailSignLabel: {
    alignSelf: "center",
    marginTop: 8,
  },
  formInput: {
    marginTop: 16,
  },
  spinnerStyle: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
  },
  termsCheckBox: {
    marginTop: 20,
  },
  termsCheckBoxText: {
    fontSize: 11,
    lineHeight: 14,
    color: "text-hint-color",
  },
});
