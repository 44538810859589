import React, { useState } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Dimensions,
  Alert,
  ActivityIndicator,
} from 'react-native';
import {
  Divider,
  Icon,
  Input,
  Spinner,
  Layout,
  Text,
  Button,
  Modal,
  List,
  ListItem,
  useTheme,
} from '@ui-kitten/components';
import { deleteAlert } from '../lib/user';
import { useUser } from '../hooks/userContext';
import {
  addToBlockedSubreddits,
  deleteFromBlockedSubreddits,
} from '../lib/user';
import { CreateAlert } from '../components';

const AlertIcon = (props) => <Icon {...props} name='alert-circle-outline' />;

const CreateIcon = (props) => <Icon {...props} name='plus-circle-outline' />;

const TrashIcon = (props) => <Icon {...props} name='trash-2-outline'></Icon>;

const MutedIcon = (props) => <Icon {...props} name='volume-off-outline' />;

const LoadingIndicator = (props) => <ActivityIndicator size='small' />;

export default ({ navigation }) => {
  const theme = useTheme();
  const { user, setUser, subscription } = useUser();
  const [subredditModalVisible, setSubredditModalVisible] = useState(false);
  const [subredditValue, setSubredditValue] = useState('');
  const [loadingMute, toggleLoadingMute] = useState(false);

  const renderItem = ({ item, index }) => {
    return (
      <ListItem
        style={{
          ...styles.listItem,
          ...(!subscription && index >= 2 && { opacity: 0.5 }),
        }}
        key={index}
        disabled={true}
        title={`${item.isExact ? '"' + item.keyword + '"' : item.keyword}${
          !subscription && index > 1 ? ' [Upgrade Required]' : ''
        }`}
        // accessoryLeft={renderItemIcon}
        accessoryRight={() => renderItemAccessory({ id: item._id })}
      />
    );
  };
  const renderItemAccessory = ({ id }) => {
    const [loading, toggleLoading] = useState(false);
    return (
      <View>
        {loading ? (
          <Spinner style={{ marginRight: 8 }} />
        ) : (
          <Button
            style={styles.button}
            appearance='ghost'
            status='danger'
            accessoryLeft={TrashIcon}
            onPress={async () => {
              toggleLoading(true);
              let updatedUser = await deleteAlert({ id });
              if (
                typeof updatedUser === 'object' &&
                updatedUser !== null &&
                !updatedUser.message
              ) {
                setUser(updatedUser);
              } else {
                toggleLoading(false);
              }
            }}
          />
        )}
      </View>
    );
  };
  const renderSubreddit = ({ item }) => {
    return (
      <ListItem
        title={`r/${item}`}
        style={styles.listItem}
        // accessoryLeft={renderItemIcon}
        accessoryRight={() => renderSubredditAccesory({ subreddit: item })}
      />
    );
  };
  const renderSubredditAccesory = ({ subreddit }) => {
    const [loading, toggleLoading] = useState(false);
    return (
      <Button
        appearance='ghost'
        status='danger'
        accessoryLeft={loading ? LoadingIndicator : TrashIcon}
        onPress={async () => {
          toggleLoading(true);
          try {
            let updatedUser = await deleteFromBlockedSubreddits(subreddit);
            if (
              typeof updatedUser === 'object' &&
              updatedUser !== null &&
              !updatedUser.message
            ) {
              await setUser(updatedUser);
            }
            Alert.alert(
              'Subreddit muted',
              `You will no longer see activity from r/${subreddit}`,
              [],
              { cancelable: false }
            );
          } catch (err) {
            console.error('Error deleting', err);
            toggleLoading(false);
          }
        }}
      />
    );
  };

  let reversedBlocked =
    user?.blockedSubreddits && [...user.blockedSubreddits].reverse();
  let reversedAlerts = user?.alerts && [...user.alerts].reverse();
  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: theme['background-basic-color-1'] }}
    >
      {/* <TopNavigation title='MyApp' alignment='center' accessoryLeft={BackAction} /> */}
      {/* <Divider /> */}
      <Layout style={styles.layoutContainer}>
        <Text category='h2'>Saved Alerts</Text>
        <View style={styles.topBar}>
          <CreateAlert />
          <Button
            // style={styles.footerControl}
            size='medium'
            status='warning'
            appearance='ghost'
            accessoryRight={MutedIcon}
            onPress={() => setSubredditModalVisible(true)}
          >
            {'Muted'}
          </Button>
        </View>
        <Divider style={{ alignSelf: 'stretch', height: 2 }} />
        <Modal
          visible={subredditModalVisible}
          style={[
            styles.modal,
            { backgroundColor: theme['background-basic-color-4'] },
          ]}
          onBackdropPress={() => setSubredditModalVisible(false)}
        >
          <View style={{ alignSelf: 'stretch' }}>
            <Text style={styles.description} category='h3'>
              Muted
            </Text>
            <Text style={styles.description} category='s1'>
              Add or remove subreddits to be muted
            </Text>
          </View>
          <Input
            // value={subredditValue}
            style={styles.input}
            placeholder='Enter the exact subreddit name'
            caption='Mute all posts and comments from this subreddit'
            autoCapitalize='none'
            // accessoryRight={renderIcon}
            captionIcon={AlertIcon}
            onChangeText={(nextValue) => setSubredditValue(nextValue)}
          />
          {user?.blockedSubreddits && (
            <List
              ItemSeparatorComponent={Divider}
              style={styles.listContainer}
              data={reversedBlocked}
              extraData={user}
              renderItem={renderSubreddit}
            />
          )}
          <Button
            disabled={!subredditValue}
            style={styles.createButton}
            accessoryLeft={loadingMute ? LoadingIndicator : CreateIcon}
            onPress={async () => {
              if (!subredditValue) return;
              toggleLoadingMute(true);
              try {
                let subreddit = subredditValue.replace('r/');
                let updatedUser = await addToBlockedSubreddits(subreddit);
                if (
                  typeof updatedUser === 'object' &&
                  updatedUser !== null &&
                  !updatedUser.message
                ) {
                  await setUser(updatedUser);
                }
                Alert.alert(
                  'Subreddit muted',
                  `You will no longer see activity from r/${subreddit}`,
                  [],
                  { cancelable: false }
                );
              } catch (err) {
                console.error('err adding to mute list', err);
              }
              setSubredditValue('');
              toggleLoadingMute(false);
            }}
          >
            Add to Muted
          </Button>

          <Button
            style={styles.dismissButton}
            onPress={() => {
              setSubredditModalVisible(false);
              toggleLoadingMute(false);
            }}
            appearance='ghost'
            status='danger'
          >
            Dismiss
          </Button>
        </Modal>
        <Divider />
        {/* <Text style={styles.description} category='h6'>Monitor all of Reddit</Text> */}
        <View style={styles.subscribeView}>
          {!subscription && user?.alerts.length > 1 && (
            <View
              style={{
                alignSelf: 'stretch',
                backgroundColor: 'black',
                justifyContent: 'center',
                padding: 20,
              }}
            >
              <Text
                style={{ textAlign: 'center' }}
                category='s1'
                status='basic'
              >
                Two keywords are included in your free plan. Start a free trial
                to track unlimited keywords.
              </Text>
              <Button
                size='small'
                style={{ marginTop: 8 }}
                status='primary'
                onPress={async () => {
                  navigation.navigate('BuyModal');
                }}
              >
                Start Free Trial
              </Button>
            </View>
          )}
          {user?.alerts && (
            <List
              ItemSeparatorComponent={Divider}
              style={styles.listContainer}
              data={reversedAlerts}
              renderItem={renderItem}
            />
          )}
        </View>
      </Layout>
    </SafeAreaView>
  );
};

let screenHeight = Dimensions.get('window').height;
let screenWidth = Dimensions.get('window').width;
const styles = StyleSheet.create({
  layoutContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 16,
  },
  topBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    paddingHorizontal: 8,
  },
  button: {
    margin: 2,
  },
  select: { width: 200 },
  description: {
    padding: 16,
    alignSelf: 'flex-start',
  },
  modal: {
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: 1,
    height: screenHeight,
    width: screenWidth,
    marginTop: 'auto',
    backgroundColor: 'white',
    paddingVertical: 32,
    paddingHorizontal: 16,
    borderRadius: 8,
  },
  input: {
    marginVertical: 8,
    alignSelf: 'stretch',
  },
  createButton: {
    marginVertical: 16,
    marginHorizontal: 16,
    alignSelf: 'stretch',
  },
  subscribeView: {
    alignSelf: 'stretch',
    flex: 1,
  },
  listItem: {
    height: 56,
  },
  dismissButton: {
    marginBottom: 16,
    marginHorizontal: 16,
  },
  listContainer: {
    alignSelf: 'stretch',
  },
});
