import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import {
  Layout,
  Divider,
  Text,
  useTheme,
  Icon,
  Input,
  Button,
} from '@ui-kitten/components';
import { Feed, CreateAlert } from '../components/';
import SegmentedControl from '@react-native-community/segmented-control';
import useDebounce from '../hooks/useDebounce';

const renderBackButton = (term, clearInput) => (
  <View style={styles.backContainer}>
    <>
      <Text category='s1' appearance='primary'>
        Showing results for "{term}"
      </Text>
    </>
    <Button appearance='ghost' onPress={() => clearInput()}>
      Back
    </Button>
  </View>
);
const SearchInput = ({ term, setTerm }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedTerm = useDebounce(searchTerm, 500);
  useEffect(
    () => {
      if (debouncedTerm) {
        setTerm(debouncedTerm.trim());
      }
    },
    [debouncedTerm, setTerm] // Only call effect if debounced search term changes
  );
  const clearInput = () => {
    setSearchTerm('');
    setTerm('');
  };

  return (
    <>
      <Input
        size='medium'
        style={styles.input}
        autoCapitalize='none'
        autoCorrect={false}
        clearButtonMode={'always'}
        placeholder={'Search for keyword or url'}
        onChangeText={(nextValue) => setSearchTerm(nextValue)}
        value={searchTerm}
      />
      {!!searchTerm && renderBackButton(searchTerm, clearInput)}
    </>
  );
};
const QuestionIcon = (props) => (
  <Icon {...props} name='question-mark-circle' fill='#8F9BB3' />
);
const OnboardingButton = (props) => (
  <Button
    {...props}
    style={{ height: 24, width: 24, marginRight: 24 }}
    name='question-mark-circle'
    appearance='ghost'
    status='basic'
    accessoryRight={QuestionIcon}
  >
    Help
  </Button>
);

export default ({ navigation }) => {
  const [selected, setSelected] = useState(0);
  const [term, setTerm] = useState('');

  const theme = useTheme();

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: theme['background-basic-color-1'] }}
    >
      <Layout style={styles.layoutContainer}>
        <View
          style={{
            height: 36,
            alignSelf: 'stretch',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 4,
          }}
        >
          <View style={{ flex: 1 }}></View>
          <Text style={{ flex: 1 }} category='h2'>
            Feed
          </Text>
          <OnboardingButton
            style={{ flex: 1 }}
            onPress={() => {
              navigation.navigate('Onboarding');
            }}
          />
        </View>
        <View
          style={{
            height: 36,
            alignSelf: 'stretch',
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 8,
            justifyContent: 'space-between',
            marginBottom: 8,
          }}
        >
          <SegmentedControl
            values={['Comments', 'Posts']}
            appearance='dark'
            style={styles.segmentedControl}
            enabled
            selectedIndex={selected}
            onChange={(event) => {
              setSelected(event.nativeEvent.selectedSegmentIndex);
            }}
          />
          <CreateAlert />
        </View>
        <Divider style={{ alignSelf: 'stretch', height: 2 }} />
        {/* <SearchInput setTerm={setTerm} /> */}
        <Feed filter={selected ? 'post' : 'comment'} searchTerm={term} />
      </Layout>
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  layoutContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingTop: 16,
    alignItems: 'center',
  },
  segmentedControl: { minWidth: 175, marginVertical: 4, fontWeight: 'bold' },
  input: {
    marginVertical: 8,
    alignSelf: 'stretch',
    minWidth: '100%',
    paddingHorizontal: 0,
    width: 300,
    paddingHorizontal: 8,
    borderRadius: 16,
  },
  backContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
    marginBottom: 8,
  },
});
