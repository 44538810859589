import React, { useContext, useState, useEffect, createContext } from 'react';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { saveNotification } from '../lib/user';
import Purchases from 'react-native-purchases';
import Constants from 'expo-constants';
import useInterval from '../hooks/useInterval';
import { getJWT } from '../lib/user';
import jwt_decode from 'jwt-decode';

const UserContext = createContext();
const useUser = () => useContext(UserContext);

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [lastReadTimestamp, setLastReadTimestamp] = useState();
  const [modalVisible, toggleModal] = useState(false);
  const [isUserLoaded, setUserLoaded] = useState(false);
  const [subscription, setSubscriptionStatus] = useState('');
  const isWeb = Platform.OS === 'web';
  const loadUserFromStorage = async () => {
    let token = await getJWT();
    AsyncStorage.getItem('user').then((value) => {
      if (typeof value === 'string' && value !== null) {
        let parsed = JSON.parse(value);
        setUser(parsed);
        setUserLoaded(true);
        return parsed;
      }
    });
  };
  // Get user from DB on app load, on fail get user from local storage
  useEffect(() => {
    try {
      loadUserFromStorage();
      AsyncStorage.getItem('subscribed').then((value) => {
        setSubscriptionStatus(value);
      });
    } catch (err) {
      console.error('ERROR', err);
    }
  }, []);

  // Get user from DB on app load, on fail get user from local storage
  useEffect(() => {
    try {
      if (typeof user === 'object' && user !== null) {
        setUserLoaded(true);
        AsyncStorage.setItem('user', JSON.stringify(user));
        checkAndUpdateSubscriptionStatus(user);
      } else {
        setUserLoaded(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  // Checks subscription status every 10 seconds. stops running once user is subscribed
  useInterval(
    () => {
      // Your custom logic here
      if (user) {
        checkAndUpdateSubscriptionStatus(user);
      }
    },
    !subscription ? 10000 : null // null stops execution
  );

  const checkAndUpdateSubscriptionStatus = async (user) => {
    try {
      if (!isWeb) {
        Purchases.setDebugLogsEnabled(true);
        Purchases.setup(Constants.manifest.extra.RC_PUBLIC_KEY, user._id);
        Purchases.setEmail(user.email);
        Purchases.setDisplayName(user.name);
        const purchaserInfo = await Purchases.getPurchaserInfo();
        console.log('Purchaser Info', purchaserInfo);
        if (
          typeof purchaserInfo.entitlements.active.premiumAlerts !== undefined
        ) {
          setSubscriptionStatus(
            purchaserInfo.entitlements.active.premiumAlerts.productIdentifier
          );
          AsyncStorage.setItem(
            'subscribed',
            purchaserInfo?.entitlements.active.premiumAlerts?.productIdentifier
          );
        } else {
          setSubscriptionStatus('');
          AsyncStorage.removeItem('subscribed');
        }
      } else if (isWeb && !subscription) {
        // Check Revenue Cat with userId to check if the user subscribed
        const url = `https://api.revenuecat.com/v1/subscribers/${user._id}`;
        const options = {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'X-Platform': 'macos',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Constants.manifest.extra.RC_API_KEY}`,
          },
        };
        fetch(url, options)
          .then((res) => res.json())
          .then((user) => {
            let subscriber = user?.subscriber;
            if (subscriber.entitlements?.premiumAlerts !== undefined) {
              setSubscriptionStatus(
                subscriber?.entitlements?.premiumAlerts?.product_identifier
              );
              AsyncStorage.setItem(
                'subscribed',
                subscriber?.entitlements?.premiumAlerts?.product_identifier
              );
            }
          })
          .catch((err) =>
            console.error('Error getting subscribption status' + err)
          );
      }
    } catch (err) {
      console.error('error', err);
    }
  };

  // If user is updated, this updates search results and storage
  const saveNotificationToken = async (token) => {
    try {
      let user = await saveNotification({ token });
      setUser(user);
    } catch (err) {
      console.error('Error saving notificaiton token', err);
    }
  };

  const signOut = async () => {
    try {
      if (isWeb) {
        await AsyncStorage.removeItem('user');
        AsyncStorage.removeItem('subscribed');
        localStorage.removeItem('user');
        localStorage.removeItem('jwt');
        setUserLoaded(false);
        setUser();
        setSubscriptionStatus('');
      } else {
        await AsyncStorage.removeItem('user');
        await SecureStore.deleteItemAsync('jwt');
        setUserLoaded(false);
        setUser();
      }
    } catch (err) {
      console.error('errror', err);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isUserLoaded,
        saveNotificationToken,
        signOut,
        toggleModal,
        modalVisible,
        subscription,
        setSubscriptionStatus,
        checkAndUpdateSubscriptionStatus,
        loadUserFromStorage,
        lastReadTimestamp,
        setLastReadTimestamp,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContextProvider, useUser };
