import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Platform, Alert } from 'react-native';
import {
  Divider,
  IndexPath,
  Toggle,
  Icon,
  Layout,
  Select,
  SelectItem,
  Text,
  Button,
  useTheme,
} from '@ui-kitten/components';
import { useUser } from '../hooks/userContext';
import { toggleNSFW, changeNotificationFrequency } from '../lib/user';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import AsyncStorage from '@react-native-async-storage/async-storage';

async function allowsNotificationsAsync() {
  let rejected = await AsyncStorage.getItem('notificationPermissionRejected');
  const settings = await Notifications.getPermissionsAsync();
  return {
    granted:
      settings.granted ||
      settings.ios?.status === Notifications.IosAuthorizationStatus.PROVISIONAL,
    rejected: !!rejected,
  };
}

export default ({ navigation }) => {
  const theme = useTheme();
  const { user, setUser, saveNotificationToken, signOut, subscription } =
    useUser();
  const [checked, setChecked] = useState(user?.allowNSFW);
  const [enabled, setEnabled] = useState(false);
  const [rejected, setRejected] = useState(false);

  useEffect(() => {
    try {
      allowsNotificationsAsync().then(({ granted, rejected }) => {
        setEnabled(granted);
        setRejected(rejected);
      });
    } catch (err) {
      console.error('Error', err);
    }
  }, []);

  const registerForPushNotificationsAsync = async () => {
    if (Constants.isDevice) {
      if (Platform.OS === 'web') return;
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        return;
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      return token;
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  };

  const ToggleNSFW = () => {
    // const [checked, setChecked] = useState(!!user.filterNSFW);
    const onCheckedChange = async () => {
      setChecked(!checked);
      try {
        toggleLoading(true);
        let updatedUser = await toggleNSFW(!checked);
        if (typeof updatedUser === 'object' && updatedUser !== null) {
          setUser(updatedUser);
        }
      } catch (err) {
        setChecked(!checked);
      }
    };
    return <Toggle checked={checked} onChange={onCheckedChange} />;
  };

  const SelectNotificationFrequency = ({ type, frequency }) => {
    const [loading, toggleLoading] = useState(false);
    const options = {
      app: ['NONE', 'ALL', 'DAILY', 'WEEKLY'],
      email: ['NONE', 'DAILY', 'WEEKLY'],
    };
    const [optionIndex, setOptionIndex] = useState(
      new IndexPath(options[type].indexOf(frequency))
    );
    const renderOption = (title) => <SelectItem title={title} key={title} />;
    const handleSelect = async (index) => {
      setOptionIndex(index);
      try {
        toggleLoading(true);
        let updatedUser = await changeNotificationFrequency(
          type,
          options[type][index.row]
        );
        toggleLoading(false);
        setUser(updatedUser);
        registerForPushNotificationsAsync().then((token) => {
          if (token) {
            saveNotificationToken(token);
          }
        });
      } catch (err) {
        console.error('Error changing frequency', err);
        toggleLoading(false);
      }
      // call select API
    };
    return (
      <Select
        selectedIndex={optionIndex}
        disabled={loading}
        value={options[type][optionIndex.row]}
        style={styles.select}
        onSelect={handleSelect}
      >
        {options[type].map(renderOption)}
      </Select>
    );
  };

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: theme['background-basic-color-1'] }}
    >
      {/* <TopNavigation title='MyApp' alignment='center' accessoryLeft={BackAction} /> */}
      {/* <Divider /> */}
      <Layout style={styles.layoutContainer}>
        <View style={styles.topBar}>
          <Text category='h2'>Settings</Text>
          <Button
            size='large'
            appearance='ghost'
            status='basic'
            // accessoryLeft={LogoutIcon}
            onPress={async () => {
              signOut();
            }}
          >
            Sign out
          </Button>
        </View>
        <View style={styles.settingsContainer}>
          <Divider />
          {/* <View style={styles.row}>
            <Text category="s1">Show NSFW Posts</Text>
            <ToggleNSFW />
          </View>
          <Divider /> */}
          {!enabled && (
            <View style={styles.row}>
              <Text category='s1'>Enable Notifications</Text>
              <Button
                size='medium'
                appearance='ghost'
                status='primary'
                onPress={async () => {
                  if (enabled) return;
                  if (rejected) {
                    Alert.alert(
                      'To enable notifications go to Settings -> Notifications and find Reply Guy. Then, turn "Allow Notifications" on.',
                      '',
                      [
                        {
                          text: 'Cancel',
                          style: 'cancel',
                        },
                        {
                          text: 'Go to Settings',
                          onPress: () => Linking.openURL('app-settings:'),
                        },
                      ],
                      { cancelable: false }
                    );
                  }
                  registerForPushNotificationsAsync().then((token) => {
                    if (token) {
                      saveNotificationToken(token);
                    }
                  });
                }}
              >
                {'Enable'}
              </Button>
            </View>
          )}
          <Divider />

          <View style={styles.row}>
            <Text category='s1'>Email</Text>
            <Text category='s1' style={{ color: theme['color-basic-500'] }}>
              {user?.email}
            </Text>
          </View>
          <Divider />
          <View style={styles.row}>
            <Text category='s1'>App Notifications</Text>
            <SelectNotificationFrequency
              type='app'
              frequency={user?.notificationFrequency || 'NONE'}
            />
          </View>
          <Divider />
          <View style={styles.row}>
            <Text category='s1'>Email Notifications</Text>
            {!subscription ? (
              <Button
                size='medium'
                appearance='ghost'
                status='primary'
                onPress={async () => {
                  navigation.navigate('BuyModal');
                }}
              >
                Upgrade Required
              </Button>
            ) : (
              <SelectNotificationFrequency
                type='email'
                frequency={user?.emailFrequency || 'NONE'}
              />
            )}
          </View>
          {/* <Divider />
          <View style={styles.row}>
            <Text category="s1">Leave Review</Text>
          </View> */}
          <Divider />
          <View style={styles.row}>
            <Text category='s1'>Pro Subscription</Text>
            {subscription ? (
              <Text category='s1' style={{ color: theme['color-basic-500'] }}>
                {'Pro Plan'}
              </Text>
            ) : (
              <Button
                size='medium'
                appearance='ghost'
                status='primary'
                onPress={async () => {
                  navigation.navigate('BuyModal');
                }}
              >
                Start Trial
              </Button>
            )}
          </View>
          <Divider />
          <View style={styles.row}>
            <Text category='s1'>Chat Support</Text>
            <Button
              size='medium'
              appearance='ghost'
              status='primary'
              onPress={() => Linking.openURL('https://replyguy.app/#/support')}
            >
              Visit ReplyGuy.app
            </Button>
          </View>
          <Divider />
          <View style={styles.row}>
            <Text category='s1'>Privacy Policy</Text>
            <Button
              size='medium'
              appearance='ghost'
              status='primary'
              onPress={() => Linking.openURL('https://replyguy.app/#/privacy')}
            >
              View
            </Button>
          </View>
          <Divider />
          <View style={styles.row}>
            <Text category='s1'>Terms of Use</Text>
            <Button
              size='medium'
              appearance='ghost'
              status='primary'
              onPress={() => Linking.openURL('https://replyguy.app/#/terms')}
            >
              View
            </Button>
          </View>
          <Divider />
        </View>
        {/* <Auth /> */}
      </Layout>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  layoutContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  topBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  button: {
    margin: 2,
  },
  select: { width: 150 },

  settingsContainer: {
    flexDirection: 'column',
    alignSelf: 'stretch',
    marginTop: 16,
  },
  row: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 56,
  },
  backdrop: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
});
