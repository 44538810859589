import React, { useState, useEffect, useCallback } from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  Alert,
  ActivityIndicator,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Card,
  Input,
  Select,
  Spinner,
  SelectItem,
  Text,
  Button,
  Modal,
  List,
  Icon,
  useTheme,
  IndexPath,
} from '@ui-kitten/components';
import { createAlert } from '../lib/user';
import cleanText from '../utils/cleanText';
import dayjs from 'dayjs';
import { searchByKeywords } from '../lib/reddit';
import useDebounce from '../hooks/useDebounce';
import SegmentedControl from '@react-native-community/segmented-control';
import { useUser } from '../hooks/userContext';

const InfoIcon = () => (
  <Icon fill='black' style={styles.infoIcon} name='info-outline' />
);

const CreateIcon = (props) => <Icon {...props} name='plus-circle-outline' />;

const AlertIcon = (props) => <Icon {...props} name='alert-circle-outline' />;

const LoadingIndicator = (props) => <ActivityIndicator size='small' />;

const renderComment = ({ item }) => (
  <>
    <Card style={styles.card}>
      <Text category='s1'>
        {item.body && cleanText({ text: item.body }).shortText}
      </Text>
      <Text category='p2' style={{ marginTop: 10 }}>
        {`${dayjs(item.created_utc * 1000).fromNow()} by u/${
          item.author
        } to r/${item.subreddit}`}
      </Text>
    </Card>
  </>
);

const renderOption = (title) => (
  <SelectItem title={title} key={title + Math.random(2)} />
);

const data = ['Contains', 'Exact Match'];

export default () => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(0);
  const [results, setResults] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [excluded, setExcluded] = useState('');
  const [loadingCreate, toggleLoadingCreate] = useState(false);
  const [searching, toggleSearching] = useState(false);
  const debouncedKeyword = useDebounce(keyword, 500);
  const [matchIndex, setMatchIndex] = useState(new IndexPath(0));
  const navigation = useNavigation();
  const { user, setUser, subscription } = useUser();

  const handleModalClose = () => {
    setResults([]);
    setExcluded();
    setSelected(0);
    toggleLoadingCreate(false);
    setVisible(false);
  };

  const handleSubmit = async () => {
    if (!keyword) return;
    toggleLoadingCreate(true);
    try {
      let updatedUser = await createAlert({
        keyword: keyword.trim(),
        isExact: !!matchIndex.row,
        ...(excluded && {
          excludedWords: excluded.trim().replace('/,/g', '').split(' '),
        }),
      });
      Alert.alert(
        'Alert created',
        `"${keyword.trim()}" was added to your alerts.`,
        [
          {
            text: 'Dismiss',
            onPress: () => console.log('Dismiss Pressed'),
            style: 'cancel',
          },
          {
            text: 'View Updated Feed',
            onPress: () => {
              navigation.navigate('Feed');
            },
            style: 'default',
          },
        ],
        { cancelable: false }
      );
      if (
        typeof updatedUser === 'object' &&
        updatedUser !== null &&
        !updatedUser.message
      ) {
        setUser((prev) => updatedUser);
      }
      handleModalClose();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (debouncedKeyword) {
      toggleSearching(true);
      searchByKeywords({
        alerts: [{ keyword: debouncedKeyword }],
        size: 15,
      }).then((results) => {
        if (results?.comment) {
          console.log('results', results);
          setResults(results.comment);
        }
        toggleSearching(false);
      });
    } else {
      setResults([]);
    }
  }, [debouncedKeyword]);
  const keyExtractor = useCallback((item) => item.id, []);

  return (
    <>
      <Button
        style={styles.topCreateButton}
        size='medium'
        appearance='ghost'
        status='success'
        accessoryLeft={CreateIcon}
        onPress={() => {
          if (!subscription && user?.alerts.length >= 2) {
            Alert.alert(
              'Maximum alerts reached. Start your free trial of unlimited alerts to continue.'
            );
            navigation.navigate('BuyModal');
          } else {
            setVisible(true);
          }
        }}
      >
        Create Alert
      </Button>
      <Modal
        visible={visible}
        // backdropStyle={styles.backdrop}
        style={[
          styles.modal,
          { backgroundColor: theme['background-basic-color-4'] },
        ]}
        onBackdropPress={() => setVisible(false)}
      >
        <View style={{ alignSelf: 'stretch' }}>
          <Text style={styles.description} category='h3'>
            Create an Alert
          </Text>
          <View
            style={[
              styles.infoBox,
              {
                backgroundColor: theme['background-alternative-color-1'],
              },
            ]}
          >
            <InfoIcon />
            <Text style={styles.explainer}>
              Think of what your customers talk about. This could include your{' '}
              <Text style={styles.bold}>brand's name</Text> or{' '}
              <Text style={styles.bold}>website</Text>, your
              <Text style={styles.bold}> competitors</Text>, or the{' '}
              <Text style={styles.bold}>problem </Text>you solve.
            </Text>
          </View>

          {/* <SegmentedControl
            values={['Keywords', 'Website URL']}
            appearance='dark'
            style={styles.segmentedControl}
            enabled
            selectedIndex={selected}
            onChange={(event) => {
              setResults([]);
              setSelected(event.nativeEvent.selectedSegmentIndex);
            }}
          /> */}
          <Text style={styles.previewText} category='h6'>
            Create a new keyword alert
          </Text>
          <Input
            size='medium'
            style={styles.input}
            autoCapitalize='none'
            autoCorrect={false}
            clearButtonMode={'always'}
            placeholder={
              selected === 0
                ? 'Enter keywords or website links'
                : 'e.g. WebsiteToTrack.com'
            }
            caption='Find the right balance between general and specific keywords'
            captionIcon={AlertIcon}
            onChangeText={(nextValue) => setKeyword(nextValue.trim())}
          />
          {selected === 0 && (
            <>
              <View
                style={{
                  flexDirection: 'row',
                  marginVertical: 8,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                }}
              >
                <Text category='h6'>Exact match?</Text>
                <Select
                  selectedIndex={matchIndex}
                  onSelect={(index) => setMatchIndex(index)}
                  value={data[matchIndex.row]}
                  style={styles.select}
                >
                  {data.map(renderOption)}
                </Select>
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 8,
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <Text style={styles.previewText} category='h6'>
                  Should not contain words
                </Text>
                <Input
                  value={excluded}
                  style={{ ...styles.input, marginBottom: 8, width: '100%' }}
                  placeholder={'Enter keywords you want to exclude'}
                  onChangeText={(nextValue) => setExcluded(nextValue)}
                  caption='Separate each word by a space. Be specific.'
                />
              </View>
            </>
          )}
        </View>
        <View style={styles.commentContainer}>
          <Text style={styles.previewText} category='h6'>
            Preview found comments
          </Text>

          {/* <Text category="h6" style={{ marginBottom: 8, alignSelf: 'center' }}> Preview Results</Text> */}
          {searching && (
            <View style={styles.spinnerContainer}>
              <Spinner status='primary' size='large' />
            </View>
          )}
          {results && (
            <List
              data={results}
              renderItem={renderComment}
              maxToRenderPerBatch={8}
              windowSize={8}
              keyExtractor={keyExtractor}
            />
          )}
        </View>

        <Button
          disabled={!keyword}
          style={styles.createButton}
          onPress={handleSubmit}
          accessoryLeft={loadingCreate ? LoadingIndicator : CreateIcon}
        >
          Create Alert
        </Button>
        <Button
          style={styles.dismissButton}
          onPress={handleModalClose}
          appearance='ghost'
          status='danger'
        >
          Dismiss
        </Button>
      </Modal>
    </>
  );
};

let screenHeight = Dimensions.get('window').height;
let screenWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  select: { width: 200, marginLeft: 16 },
  explainer: {
    fontSize: 14,
    color: 'black',
    opacity: 1,
    fontWeight: '400',
    paddingRight: 6,
  },
  bold: { fontWeight: '800', fontSize: 14, color: 'black' },
  topCreateButton: {
    margin: 0,
    paddingVertical: 4,
    justifyContent: 'center',
  },
  description: {
    paddingVertical: 16,
    marginTop: 8,
    alignSelf: 'flex-start',
  },
  modal: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    height: screenHeight,
    width: screenWidth,
    marginTop: 'auto',
    backgroundColor: 'white',
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderRadius: 8,
  },
  input: {
    marginVertical: 8,
    alignSelf: 'stretch',
    minWidth: '100%',
    paddingHorizontal: 0,
    width: 300,
  },
  createButton: {
    marginTop: 8,
    marginHorizontal: 16,
    alignSelf: 'stretch',
  },
  dismissButton: {
    marginHorizontal: 16,
  },
  spinnerContainer: {
    alignSelf: 'stretch',
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  commentContainer: {
    flex: 1,
    alignSelf: 'stretch',
  },
  previewText: {
    marginVertical: 8,
  },
  infoIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
  infoBox: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 8,
    borderRadius: 8,
    marginVertical: 4,
    lineHeight: 1.5,
  },
});
