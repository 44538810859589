import React, { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components';
import { Home, Alerts, Settings, Signin, StripeCheckout } from './screens';
import BuyModal from './screens/BuyModal';
import { useUser } from './hooks/userContext';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import OnboardingSwiper from './components/OnboardingSwiper';
const isApp = Platform.OS !== 'web';

// let OnboardingSwiper;
// if (isApp) {
//   console.log('Here', require('./components/OnboardingSwiper').default);
//   Onboardingswiper = require('./components/OnboardingSwiper').default;
//   console.log('Onboarding Swiper~~', OnboardingSwiper);
// }

const registerForPushNotificationsAsync = async () => {
  if (Constants.isDevice) {
    if (Platform.OS === 'web') return;
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    // Permission granted
    if (existingStatus === 'granted')
      AsyncStorage.removeItem('notificationPermissionRejected');

    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      AsyncStorage.setItem('notificationPermissionRejected', 'rejected');
      alert('Failed to get token for push notification!');
      // store "permissions rejected" in async storage, use it to open in settings
      return;
    }
    let experienceId = '@pakaplace/reply-tool';
    const token = (await Notifications.getExpoPushTokenAsync({ experienceId }))
      .data;
    return token;
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }
};

const Stack = createStackNavigator();
const RootStack = createStackNavigator();

const { Navigator, Screen } = createBottomTabNavigator();

const BottomTabBar = ({ navigation, state }) => (
  <BottomNavigation
    selectedIndex={state.index}
    onSelect={(index) => navigation.navigate(state.routeNames[index])}
    style={{ height: 64 }}
  >
    <BottomNavigationTab title='FEED' />
    <BottomNavigationTab title='MY ALERTS' />
    <BottomNavigationTab title='SETTINGS' />
  </BottomNavigation>
);

const HomeBottomNavigator = () => (
  <Navigator tabBar={(props) => <BottomTabBar {...props} />}>
    <Screen name='Feed' component={Home} />
    <Screen name='Alerts' component={Alerts} />
    <Screen name='Settings' component={Settings} />
  </Navigator>
);

export const AuthNavigator = ({ navigation }) => {
  const { loadUserFromStorage } = useUser();
  useEffect(() => {
    loadUserFromStorage().then((user) => {
      if (user) navigation.navigate('Home');
    });
  }, []);

  return (
    <Stack.Navigator headerMode='none'>
      <Stack.Screen name='Signin' component={Signin} />
    </Stack.Navigator>
  );
};

function RootStackScreen() {
  const { user } = useUser();
  let initialRoute =
    Platform.OS === 'web'
      ? 'Home' // change back to home
      : user?.completedOnboarding
      ? 'Home'
      : 'Onboarding';
  // let initialRoute = "Home";
  return (
    <RootStack.Navigator initialRouteName={initialRoute}>
      <RootStack.Screen
        name='Onboarding'
        component={OnboardingSwiper}
        options={{ headerShown: false }}
      />
      <RootStack.Screen
        name='Home'
        component={HomeBottomNavigator}
        options={{ headerShown: false }}
      />
      <RootStack.Screen
        name='BuyModal'
        component={BuyModal}
        options={{ headerShown: false }}
      />

      {/*       <RootStack.Screen name='Signin' options={{ headerShown: false }} component={SignIn} /> */}
    </RootStack.Navigator>
  );
}

export const AppNavigator = () => {
  // const [isSignedIn, setSignedIn] = useState(false)
  const { isUserLoaded, saveNotificationToken } = useUser();
  useEffect(() => {
    if (isUserLoaded) {
      registerForPushNotificationsAsync().then((token) => {
        if (token) {
          saveNotificationToken(token);
        }
      });
    }
  }, [isUserLoaded]);

  return (
    <NavigationContainer>
      <Stack.Navigator headerMode='none'>
        {!isUserLoaded ? (
          <Stack.Screen name={'Signin'} component={AuthNavigator} />
        ) : (
          <Stack.Screen name={'Root'} component={RootStackScreen} />
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};
