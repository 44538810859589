import Constants from 'expo-constants';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';
export const getJWT = async () => {
  let jwt;
  if (Platform.OS === 'web') {
    jwt = localStorage.getItem('jwt');
  } else {
    jwt = await SecureStore.getItemAsync('jwt');
  }
  return jwt;
};
export const saveNotification = async ({ token }) => {
  let deviceType =
    Platform.OS === 'ios'
      ? 'ios'
      : Platform.OS === 'android'
      ? 'android'
      : null;
  let jwt = await getJWT();
  const response = await fetch(
    `${Constants.manifest.extra.API_URL}/user/notification`,
    {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'x-access-token': jwt,
      },
      body: JSON.stringify({ token, ...(deviceType && { deviceType }) }),
    }
  );
  if (!response.ok) throw new Error(response.status);
  let parsed = await response.json();
  return parsed;
};
export const createAlert = async ({
  keyword,
  isExact = false,
  excludedWords = [],
}) => {
  let jwt = await getJWT();
  const response = await fetch(
    `${Constants.manifest.extra.API_URL}/user/alert`,
    {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'x-access-token': jwt,
      },
      body: JSON.stringify({ keyword, isExact, excludedWords }),
    }
  );
  if (!response.ok) throw new Error(response.status);
  let parsed = await response.json();
  return parsed;
};
export const deleteAlert = async ({ id }) => {
  try {
    let jwt = await getJWT();
    const response = await fetch(
      `${Constants.manifest.extra.API_URL}/user/alert`,
      {
        method: 'delete',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
          'x-access-token': jwt,
        },
        body: JSON.stringify({ id }),
      }
    );
    if (!response.ok) throw new Error(response.status);
    let parsed = await response.json();
    return parsed;
  } catch (err) {
    return console.error('Error', err);
  }
};
export const addToBlockedSubreddits = async (subreddit) => {
  let jwt = await getJWT();
  if (typeof subreddit !== 'string')
    return new Error('Subreddit must be a string');
  const response = await fetch(
    `${Constants.manifest.extra.API_URL}/user/blocklist`,
    {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'x-access-token': jwt,
      },
      body: JSON.stringify({ subreddit: subreddit.toLowerCase() }),
    }
  );
  if (!response.ok) throw new Error(response.status);
  let parsed = await response.json();
  return parsed;
};
export const deleteFromBlockedSubreddits = async (subreddit) => {
  let jwt = await getJWT();
  if (typeof subreddit !== 'string')
    return new Error('Subreddit must be a string');
  const response = await fetch(
    `${Constants.manifest.extra.API_URL}/user/blocklist`,
    {
      method: 'delete',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'x-access-token': jwt,
      },
      body: JSON.stringify({ subreddit: subreddit.toLowerCase() }),
    }
  );
  if (!response.ok) throw new Error(response.status);
  let parsed = await response.json();
  return parsed;
};
export const toggleNSFW = async (allowNSFW) => {
  let jwt = await getJWT();
  const response = await fetch(
    `${Constants.manifest.extra.API_URL}/user/filter`,
    {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'x-access-token': jwt,
      },
      body: JSON.stringify({ filter: allowNSFW }),
    }
  );
  if (!response.ok) throw new Error(response.status);
  let parsed = await response.json();
  return parsed;
};
export const changeNotificationFrequency = async (type, frequency) => {
  let jwt = await getJWT();
  const response = await fetch(
    `${Constants.manifest.extra.API_URL}/user/notificationfrequency`,
    {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'x-access-token': jwt,
      },
      body: JSON.stringify({ type, frequency }),
    }
  );
  if (!response.ok) throw new Error(response.status);
  let parsed = await response.json();
  return parsed;
};
export const completeOnboarding = async (type, frequency) => {
  let jwt = await getJWT();
  const response = await fetch(
    `${Constants.manifest.extra.API_URL}/user/onboarding`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'x-access-token': jwt,
      },
    }
  );
  if (!response.ok) throw new Error(response.status);
  let parsed = await response.json();
  return parsed;
};
export const updateReadTimeStamp = async (type, lastReadTimestamp) => {
  let jwt = await getJWT();
  try {
    await fetch(`${Constants.manifest.extra.API_URL}/user/readCommentPost`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'x-access-token': jwt,
      },
      body: JSON.stringify({ type: type, created: lastReadTimestamp }),
    }).then(async (response) => {
      if (!response.ok) throw new Error(response.status);
      let parsed = await response.json();
      return parsed;
    });
  } catch (error) {
    console.error('Error updating timestamp', error);
  }
};
